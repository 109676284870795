import { useEffect } from 'react'
import {
  gql,
  useQuery,
} from '@apollo/client';

type argsType = {
  limit: Number,
  tags: Array<string>
}

const useLoadArticles = ({
  limit,
  tags
}: argsType) => {
  const { loading, error, data, fetchMore, refetch } = useQuery(ARTICLE_SUMMARIES_QUERY, {
    variables: {
      first: limit,
      tags: tags?.map(tag => parseInt(tag)) || []
    },
  });

  useEffect(() => {
    refetch()
  }, [tags, refetch])

  return { loading, error, data, fetchMore, refetch }
}

const ARTICLE_SUMMARIES_QUERY = gql`
  query ArticleSummaries($cursor: String, $first: Int, $tags: [Int!]) {
    articleSummaries(first: $first, after: $cursor, tags: $tags) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          title
          body
          link
          featuredImage
          createdAtInAgo
          user {
            id
            firstName
            lastName
          }
          infoWebsite {
            id
            name
            link
          }
          tags {
            id
            name
          }
          meta
        }
      }
    }
  }
`

export default useLoadArticles