import React from 'react';
import { gql, useMutation } from '@apollo/client'

import {
  ArticleType,
} from '../types/modelTypes';

const BUTTONS_ENABLED = true;
const MAX_TWEET_CHARS = 480;

type propTypes = {
  article: ArticleType,
  showModal: (body: JSX.Element[]) => void
}

const Article = (props: propTypes) => {
  const { article } = props;
  const [updateArticleSummary] = useMutation(UPDATE_ARTICLE_SUMMARY_MUTATION)

  const onClickToTweet = (e: React.MouseEvent, summary: ArticleType) => {
    e.preventDefault();
    const div = document.createElement("div"); 
    div.innerHTML = summary.body; 
    let text = div.textContent || div.innerText || ""
    const [firstPar, ...rest] = text.split('.')
    text = [firstPar, summary.link + '\n\n', ...rest].join(' ')
    const matcher = new RegExp(`(.|[\r\n]){1,${MAX_TWEET_CHARS}}(\\s|\\r|\\n|$)`, 'g')
    const chunks = text.match(matcher) || []
    /*
    const children = chunks.map((chunk, idx) => 
      <div className="tweet-box" 
        data-before={`\\${idx + 1}`} 
        contentEditable={true}>
          {chunk}
      </div>
    )
    showModal(children)
    return
    */

    const tweetableText = "https://twitter.com/intent/tweet?url=" + summary.link + "&text=" + encodeURIComponent(chunks.join('\n\n'));
  
    window.open(
      tweetableText,
      "twitterwindow", 
      "height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
    );

    const variables = {
      "id": parseInt(summary.id),
      "meta": { "shares": { "twitter": 1 } }
    }

    updateArticleSummary({ variables })
  }
  
  const { infoWebsite, tags }: ArticleType = article;
  const shareCount = article.meta?.shares?.twitter
  return (
    <div className="article">
      <h3>
        <a href={article.link} target="_blank" rel="noreferrer">
          {article.title}
        </a>
      </h3>
      {article.featuredImage && <img alt={article.title} src={article.featuredImage} className="featured-image" />}
      <p className="meta-info">
        {tags?.map((tag, idx: number) => <span>{tag.name}{(idx < tags.length - 1) && ', '}</span>)}&nbsp;
        | {article.meta?.article_date ? 
            `Published ${article.meta?.article_date}` : 
            `Shared ${article.createdAtInAgo} ago`}.
          &nbsp;Source: {infoWebsite?.link}
      </p>
      <p className="article-body" dangerouslySetInnerHTML={{__html: article.body}} />
      {
        BUTTONS_ENABLED && (
          <div className="buttons-wrapper">
            <div className="pull-left">
              <button onClick={(e: React.MouseEvent) => onClickToTweet(e, article)}>Tweet</button>
              <button onClick={() => window.open(article.link, '_blank')}>Read Article</button>
            </div>
            <div className="pull-right">
              { shareCount ? `${shareCount} ${ shareCount === 1 ? 'tweet' : 'tweets'}` : ''}
            </div>
          </div>
        )
      }
    </div>
  );
};

const UPDATE_ARTICLE_SUMMARY_MUTATION = gql`
  mutation UpdateArticleSummary($id: Int!, $meta: JSON!) {
    updateArticleSummary(input: { params: { id: $id, meta: $meta}}) {
      articleSummary {
        id
        title
        meta
      }
    }
  }
`
export default Article;