import React, { useEffect, useCallback } from 'react'
import { useCookies } from 'react-cookie'
import { useQuery, gql } from '@apollo/client'
import { TagType } from '../types/modelTypes'

type propTypes = {
  setTags: any
}

const Tags = ({ setTags }: propTypes) => {
  const { loading, error, data } = useQuery(TAGS_QUERY)
  const [cookies, setCookie] = useCookies(['tags'])

  const getTags = useCallback(
    () => cookies.tags?.split('|') || [],
    [cookies.tags],
  );

  useEffect(() => {
    setTags(getTags())
  }, [setTags, getTags])

  if (loading) {
    return (<p>loading categories ...</p>)
  }

  if (error) {
    return <p>Loading categories failed.</p>
  }

  const onSelect = (e: any) => {
    let tags = getTags()
    if (e.target.checked && tags.indexOf(e.target.value) === -1) {
      tags.push(e.target.value)
    } 
    if (!e.target.checked) {
      tags = tags.filter((id: any) => id !== e.target.value)
    }
    setCookie('tags', tags.join('|'))
    setTags(tags)
  }

  return (
    <div className="tags">
      <h3>Categories {cookies.name}</h3>
        {
          data && data.tags?.map((tag: TagType) => (
            <>
              <input
                type="checkbox"
                checked={getTags().find((id: any) => tag.id === id)}
                name={tag.name}
                value={tag.id}
                onClick={onSelect}
              />
              <label htmlFor={tag.name}>{tag.name}</label><br/>
            </>
          ))
        }
    </div>
  )
}

const TAGS_QUERY = gql`
query Tags {
  tags {
    id
    name
  }
}
`
export default Tags