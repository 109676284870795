import React, { useEffect, useState, useCallback } from 'react'
import Articles from '../components/articles'
import Tags from '../components/tags'

type props = {
  children?: React.ReactChildren
  isIframeMode: boolean
}

const Main = ({ isIframeMode, children }: props) => {
  const [tags, setTags] = useState([])
  const [currTags, setCurrTags] = useState([])

  useEffect(() => {
    setTags(currTags)
  }, [currTags])

  const tagIdsFromIframe = useCallback(() => {
    if(!isIframeMode) return [];
    const tagsString = window.location.search.split('=').pop()
    return tagsString?.split(',') || [];
  }, [isIframeMode])

  return (
  <div className="main">
    <div className={isIframeMode ? "" : "body"}>
      <Articles tags={isIframeMode ? tagIdsFromIframe() : tags} />
    </div>
    {!isIframeMode && (
      <aside className="sidebar hidden-xs">
        <div className="mission">
          <p>
            For those periods you would love to kill time in-between meetings with helpful articles
          </p>
          <p>
            We keep this updated so you make the best use of your scarce time.
          </p>
        </div>
        <Tags setTags={setCurrTags} />
      </aside>
    )}
    {children}
  </div>
)
}

export default Main