import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client';

import './subscription-form.scss'

const validateEmail = (mail: string): boolean =>
  (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail))

const SubscriptionForm = () => {
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  const [addTodo] = useMutation(ADD_USER_MUTATION);

  const changeEmail = (email: string) => {
    setEmail(email)
    setEmailValid(validateEmail(email))
  }

  return (
    <form className="subscription-form"
          onSubmit={e => {
            e.preventDefault();
            addTodo({ variables: { firstName, email } })
              .then( () => {
                alert(`Hey ${firstName}, thanks for subscribing.`)
                setFirstName('')
                changeEmail('')
              })
              .catch(() => alert('An error occurred. Please try again, or tweet at @hera.marketing or @centamadi'));
          }}>
      <div className="title">Get these summaries in your inbox</div>
      <div className="fields">
        <input type="text"
                name="first_name"
                size={12}
                placeholder="First Name"
                onChange={(e) => setFirstName(e.target.value)} 
                value={firstName} />
        <input type="email" 
                name="email" 
                placeholder="Email"
                onChange={(e) => changeEmail(e.target.value)} 
                value={email} />
        <button disabled={!(firstName && email) || !emailValid} 
                type="submit">
          Submit
        </button>
      </div>
    </form>
  )
}


const ADD_USER_MUTATION = gql`
  mutation CreateUser($firstName: String!, $email: String!) {
    addUser(input: { params: { firstName: $firstName, email: $email}}) {
      user {
        firstName
        email
      }
    }
  }
`;

export default SubscriptionForm