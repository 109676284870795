import React, { useEffect, useRef } from 'react'
import './Modal.scss'


const implementModal = (modal: any, btn: any, closeBtn: any) => {
  if (!btn) return

  // When the user clicks on the button, open the modal
  btn.onclick = function() {
    modal.style.display = "block";
  }

  // When the user clicks on <span> (x), close the modal
  closeBtn.onclick = function() {
    modal.style.display = "none";
  }

  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function(event: any) {
    if (event.target === modal) {
      modal.style.display = "none";
    }
  }
}

type propTypes = {
  btn: any,
  body?: JSX.Element[] | JSX.Element,
  modalEl: React.RefObject<HTMLDivElement>,
  onBtnClick: (tweet: string[]) => void
}

const Modal = ({
  btn,
  body,
  modalEl,
  onBtnClick,
}: propTypes) => {
  const closeBtn: React.RefObject<HTMLButtonElement> = useRef(null)

  useEffect(() => {
    implementModal(modalEl.current, btn.current, closeBtn.current)
  }, [btn, modalEl])

  return (
    <div id="myModal" ref={modalEl} className="modal">
      <div className="content">
        <div className="modal-header">
          <span ref={closeBtn} className="close">&times;</span>
          <h3>Compose Tweet</h3>
        </div>

        <div className="modal-content">
          {body}
        </div>

        <div className="modal-footer">
          <button onClick={(e) => {
            onBtnClick([])
            closeBtn.current && closeBtn.current.click();
          }}>
          Tweet
        </button>
        </div>
      </div>
    </div>
  )
}

export default Modal