import React from 'react'
import SubscriptionForm from '../subscription/SubscriptionForm'

const Header = () => (
    <div className="header">
      <div className="pull-left">
        Tweetspiration
        |
        <span>Read. Tweet. Repeat.</span>
      </div>
      <div className="pull-right">
        <SubscriptionForm />
      </div>
    </div>
)

export default Header