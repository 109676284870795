import React from 'react';
import Header from './components/header'
import Footer from './components/footer'
import Main from './layouts/Main'

import './App.css';

function App() {
  const isIframeMode: boolean = window.location !== window.parent.location;

  return (
    <div className="App">
      {!isIframeMode && <Header />}
      <Main isIframeMode={isIframeMode} />
      {!isIframeMode && <Footer />}
    </div>
  );
}

export default App;
