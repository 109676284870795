import React, { useState, useEffect, useCallback, useRef } from 'react';

import Article from './Article';
import { ArticleType } from '../types/modelTypes';
import Modal from '../modal'
import useLoadArticles from './useLoadArticles'
import './Articles.scss'


type propTypes = {
  tags: Array<string>
}

const Articles = ({
  tags = [],
}: propTypes) => {
  const [limit] = useState(15);
  const [modalBody, setModalBody]: [modalBody: JSX.Element[] | undefined, setModalBody: any] = useState(undefined);
  
  const { loading, error, data, fetchMore } = useLoadArticles({
    limit,
    tags
  })
  
  const currentLength = data?.articleSummaries?.edges.length;
  const endCursor = data?.articleSummaries?.pageInfo?.endCursor;
  let bottomBoundaryRef = useRef(null);

  const loadMoreResults = useCallback(
    () => fetchMore({
      variables: {
        first: currentLength,
        cursor: endCursor,
        tags: tags.map(id => parseInt(id))
      },
      updateQuery: (previousResult: any, {fetchMoreResult}: {fetchMoreResult?: any}) => {
        const newEdges = fetchMoreResult.articleSummaries.edges;
        const pageInfo = fetchMoreResult.articleSummaries.pageInfo;
        return newEdges.length
          ? {
              articleSummaries: {
                __typename: previousResult.articleSummaries.__typename,
                edges: [...previousResult.articleSummaries.edges, ...newEdges],
                pageInfo,
              },
            }
          : previousResult;
      },
    }),
    [currentLength, endCursor, fetchMore, tags]
  );

  const scrollObserver = useCallback(
    node => {
      new IntersectionObserver(entries => {
        entries.forEach(en => {
          if (en.intersectionRatio > 0) {
            loadMoreResults()
          }
        });
      }).observe(node);
    },
    [loadMoreResults]
  );
  
  useEffect(() => {
    if (bottomBoundaryRef.current) {
      scrollObserver(bottomBoundaryRef.current);
    }
  }, [scrollObserver, bottomBoundaryRef]);

  const modal: React.RefObject<HTMLDivElement> = useRef(null)
  const modalBtn: React.RefObject<HTMLButtonElement> = useRef(null)

  const tweetThread = (tweet: Array<string>) => {
    alert('Tweet sent successfully');
  } 

  const showModal = (body: JSX.Element[]) => {
    setModalBody(body)
    const el = modal.current
    if(el && el.style) {
      el.style.display = "block"
    }
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className="articles">
      <Modal
        modalEl={modal}
        body={modalBody}
        onBtnClick={tweetThread}
        btn={modalBtn} />
      {data?.articleSummaries?.edges?.map(({node: article}: {node: ArticleType}) => (
        <Article key={article.id} article={article} showModal={showModal} />
      ))}
      <div>
        {
          data?.articleSummaries?.pageInfo?.hasNextPage && (
            <button
              ref={bottomBoundaryRef}
              onClick={loadMoreResults}>
              Load More
            </button>
          )
        }
      </div>
      <button style={{ display: 'none' }} ref={modalBtn} />
    </div>
  );
};



export default Articles;